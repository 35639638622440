import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import {
  fetchDocuments,
  selectAllDocuments,
  uploadDocument,
} from '../../slices/documents/documentsSlice';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import { CircularProgress, Paper, Table, TableBody, TableContainer } from '@mui/material';
import { selectUser } from '../../slices/user/userSlice';
import { ProgressOverlay } from '../../common/progress-overlay/ProgressOverlay';
import { toast } from 'react-toastify';
import { TableHeader } from '../category/components/TemplatesTable/components/TableHeader';
import { StyledTableRow } from '../category/components/TemplatesTable/components/TableRow';
import { StyledTableCell } from '../category/components/TemplatesTable/components/TableCell';
import { ActionsCell } from './components/ActionsCell';
import { DocumentTemplateModel } from '../../models/document.template.model';
import { NWClient } from '../../client/NWClient';

const DocumentsCategoryPage = () => {
  const token = localStorage.getItem('access_token');
  const { category } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const formRef = useRef(null);
  const documents = useAppSelector(selectAllDocuments);
  const currentUser = useAppSelector(selectUser);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [pendingStatus, setPendingStatus] = useState(false);
  const [documentsFetched, setDocumentsFetched] = useState(false);
  const [uploadControlOpened, setUploadControlOpened] = useState<boolean>(true);
  const [templates, setTemplates] = useState<DocumentTemplateModel[]>([]);
  const documentsFiltered =
    category === 'templates'
      ? templates
      : documents.filter((el) => el.category === category && el.user === currentUser.id);
  const uploadDocumentFn = () => {
    setPendingStatus(true);
    const data = new FormData(formRef.current);
    data.set('user', String(currentUser.id));
    data.set('category', category);
    dispatch(uploadDocument({ token, data }))
      .unwrap()
      .then(() => {
        setUploadedFiles([]);
        if (formRef && formRef.current) {
          formRef.current.reset();
        }
        toast.success(t('messages.documentUploaded'), { theme: 'colored' });
      })
      .catch((error) => {
        toast.error(error.message || t('messages.errorOccurred'), { theme: 'colored' });
      })
      .finally(() => setPendingStatus(false));
  };

  const setUploadButtonText = () => {
    if ((!uploadControlOpened && documentsFiltered.length > 0) || documentsFiltered.length === 0) {
      return t('buttons.upload');
    } else if (uploadControlOpened && documentsFiltered.length > 0) {
      return t('buttons.save');
    }
  };
  const handleUploadButtonClick = () => {
    if (uploadControlOpened || documentsFiltered.length === 0) {
      uploadDocumentFn();
    } else {
      setUploadControlOpened(true);
    }
  };

  useEffect(() => {
    if (documents.length === 0 && !documentsFetched) {
      setDocumentsFetched(true);
      dispatch(fetchDocuments(token))
        .catch(() => {
          toast.error(t('messages.errorOccurred'), { theme: 'colored' });
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (category === 'templates') {
      NWClient.list(token, 'document-template', { user: currentUser.id })
        .then((res: DocumentTemplateModel[]) => {
          setTemplates(res);
        })
        .catch(() => {
          toast.error(t('messages.errorOccurred'), { theme: 'colored' });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [documents]);
  return (
    <div>
      <h1 className='text-capitalize text-center mb-3'>{category}</h1>
      {loading ? (
        <div className='text-center'>
          <CircularProgress />
        </div>
      ) : (
        <>
          {category !== 'templates' && (
            <>
              <Dropzone
                noDrag={true}
                accept={{
                  'text/txt': ['.txt'],
                  'text/csv': ['.csv'],
                  'application/msword': ['.doc'],
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
                    '.docx',
                  ],
                  'text/html': ['.html'],
                  'application/pdf': ['.pdf'],
                }}
                onDrop={(acceptedFiles) => {
                  setUploadedFiles(acceptedFiles);
                }}
                maxFiles={1}
              >
                {({ getRootProps, getInputProps }) => (
                  <form ref={formRef}>
                    <div className='dropzone-wrapper'>
                      <div {...getRootProps()}>
                        <input
                          name='document_file'
                          type='file'
                          title='Upload a file'
                          {...getInputProps()}
                          multiple={false}
                        />
                        <UploadFileIcon fontSize='large' />
                        <p className='dropzone-message'>
                          <span className='link-style'>
                            {uploadedFiles.length > 0 ? '' : t('messages.clickToUpload') + '.'}
                          </span>
                        </p>
                      </div>
                      {uploadedFiles.length > 0 && (
                        <div className='success'>
                          {/*t('labels.uploadedFile')*/}
                          {uploadedFiles.map((el) => (
                            <span key={el.name}>{el.name}</span>
                          ))}
                        </div>
                      )}
                    </div>
                  </form>
                )}
              </Dropzone>

              <div className='buttons-row d-flex justify-content-end align-items-center mb-3 mt-3'>
                {uploadedFiles.length > 0 && (
                  <Button
                    role='button'
                    type='button'
                    onClick={() => {
                      setUploadedFiles([]);
                      formRef.current.reset();
                    }}
                  >
                    {t('buttons.reset')}
                  </Button>
                )}
                <Button
                  style={{ marginLeft: '0.5rem' }}
                  role='button'
                  disabled={
                    documentsFiltered.length === 0
                      ? uploadedFiles.length === 0
                      : uploadedFiles.length === 0 && uploadControlOpened
                  }
                  variant='contained'
                  onClick={() => handleUploadButtonClick()}
                >
                  {setUploadButtonText()}
                </Button>
              </div>
            </>
          )}
          {documentsFiltered.length > 0 ? (
            <TableContainer
              elevation={0}
              sx={{ py: 2, px: 3, borderRadius: 5 }}
              variant='outlined'
              component={Paper}
            >
              <Table aria-label='templates table'>
                <TableHeader />
                <TableBody sx={{ px: 2 }}>
                  {documentsFiltered.map((row) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell
                        component='th'
                        scope='row'
                        onClick={() =>
                          navigate(
                            category === 'templates'
                              ? `${pathName}/${row.id}?initialTab=1`
                              : `${pathName}/${row.id}`
                          )
                        }
                      >
                        <div className='templates-table-name'>
                          {row.name
                            ? row.name
                            : documents.find((el) => el.id === row.document_upload)?.name +
                              `_${row.updated}`}
                        </div>
                      </StyledTableCell>
                      <ActionsCell align='right' row={row} />
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}
        </>
      )}
      {pendingStatus ? <ProgressOverlay /> : ''}
    </div>
  );
};

export default DocumentsCategoryPage;

import React, {useState, useRef, useEffect, ChangeEvent} from 'react';
import {TabPanel} from '../../../../common/tabpanel/TabPanel';
import {TabProps} from './types';
import {Button, Grid} from '@mui/material';
import {useTranslation} from 'react-i18next';
import JoditEditor from 'jodit-react';
import {useNavigate, useParams} from 'react-router-dom';
import {ProgressOverlay} from '../../../../common/progress-overlay/ProgressOverlay';
import {NWClient} from '../../../../client/NWClient';
import {toast} from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AIIcon from '../../../../../static/assets/svg/ai-icon.svg';
import DownloadIcon from '../../../../../static/assets/svg/download-icon.svg';
import DocumentSigningIcon from '../../../../../static/assets/svg/menu/documents-signing.svg';
import DialogItem from '../../../../common/dialog-item/DialogItem';
import InfoIcon from '@mui/icons-material/Info';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {DocumentSigningModel} from '../../../../models/document.signing.model';
import {ROUTES} from '../../../../common/constants/routes';
import {useAppSelector} from '../../../../common/hooks/useAppSelector';
import {selectUser} from '../../../../slices/user/userSlice';
import PacmanLoader from 'react-spinners/PacmanLoader';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import parse from 'html-react-parser';
import {DocumentHtmlModel} from "../../../../models/document.html.model";

const icon = <CheckBoxOutlineBlankIcon fontSize='small'/>;
const checkedIcon = <CheckBoxIcon fontSize='small'/>;

export const DocumentContentTab = ({
                                     currentTab,
                                     htmlContent,
                                     documentName,
                                     documentUploadId,
                                   }: TabProps) => {
  const {t} = useTranslation();
  const purposes = [
    {
      title: t('purpose.verification'),
      value: 'verification',
    },
    {
      title: t('purpose.analysis'),
      value: 'analysis',
    },
    {
      title: t('purpose.recordKeeping'),
      value: 'record keeping',
    },
  ];
  const path = location.pathname;
  const token = localStorage.getItem('access_token');
  const {documentId, category} = useParams();
  const navigate = useNavigate();
  const currentUser = useAppSelector(selectUser);
  const [editable, setEditable] = useState<boolean>(false);
  const [aiDocumentDialogVisible, setAiDocumentDialogVisible] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [documentContent, setDocumentContent] = useState('');
  const [formatMessage, setFormatMessage] = useState(false);
  const [documentType, setDocumentType] = useState('contract');
  const [purpose, setPurpose] = useState(purposes);
  const [resultFormat, setResultFormat] = useState('table');
  const [detailLevel, setDetailLevel] = useState('high level');
  const [simplifyJargon, setSimplifyJargon] = useState(true);
  const [contextualInformation, setContextualInformation] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [analysisLoading, setAnalysisLoading] = useState<boolean>(false);
  const editor = useRef(null);

  const handleAnalyze = () => {
    setAnalysisLoading(true);
    NWClient.post(
        token,
        'ai-assist',
        {
          document_upload_id: Number(documentId),
          document_type: documentType,
          result_format: resultFormat,
          detail_level: detailLevel,
          purpose: JSON.stringify(purpose.map((el) => el.value)),
          simplify_jargon: simplifyJargon,
          contextual_information: contextualInformation,
        },
        true
    )
        .then(() => {
          window.location.href = `${path}?initialTab=0`;
        })
        .catch((error) => {
          toast.error(error.message ? error.message : t('messages.errorOccurred'), {
            theme: 'colored',
          });
        });
  };
  const handleDownloadLink = (id: number) => {
    setLoading(true);
    NWClient.downloadDocument(token, Number(documentId), documentName)
        .catch(() => {
          toast.error(t('messages.errorOccurred'), {theme: 'colored'});
        })
        .finally(() => {
          setLoading(false);
        });
  };
  const saveContent = () => {
    setLoading(true);
    const data = {html: documentContent, document_upload: Number(documentId)};
    NWClient.post(token, 'document-upload-html', data)
        .then(() => {
          toast.success(t('messages.documentContentSaved'), {theme: 'colored'});
          window.location.reload();
        })
        .catch((error) => {
          toast.error(error.message || t('messages.errorOccurred'), {theme: 'colored'});
        })
        .finally(() => setLoading(false));
  };

  const updateTemplate = () => {
    setLoading(true);
    const data = {
      content: documentContent,
      user: currentUser.id,
      document_upload: documentUploadId,
    };
    NWClient.put(token, 'document-template', Number(documentId), data, true)
        .then(() => {
          toast.success(t('messages.templateUpdated'), {theme: 'colored'});
          setEditable(true);
        })
        .catch((error) => {
          toast.error(error.message || t('messages.errorOccurred'), {theme: 'colored'});
        })
        .finally(() => setLoading(false));
  };

  const handleSign = (document_upload: number, documentFormat: string) => {
    if (documentFormat.toLowerCase() === 'pdf') {
      const data = {
        user: currentUser.id,
        document_upload,
        //signing_doc_id: 1, //should be optional later, to refactor
      };
      setLoading(true);
      NWClient.post(token, 'document-sign', data, true)
          .then((res: DocumentSigningModel) => {
            navigate(`${ROUTES.DOCUMENTS}/${res.signing_doc_id}`);
          })
          .finally(() => {
            setLoading(false);
          });
    } else {
      setFormatMessage(true);
    }
  };

  useEffect(() => {
    setDocumentContent(htmlContent);
  }, [htmlContent]);

  return (
      <>
        <TabPanel value={currentTab} index={1} prefix={'vertical'}>
          <div
              className='buttons-row content-buttons align-items-center bg-white position-sticky p-4 d-flex flex-wrap justify-content-end'>
            {category !== 'templates' ? (
                <>
                  <Tooltip title={t('messages.AISummaryMessage')} placement='top' arrow>
                    <IconButton
                        className='table-row-icon-button'
                        onClick={() => {
                          setAiDocumentDialogVisible(true);
                        }}
                    >
                      <AIIcon/>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('buttons.download')} placement='top' arrow>
                    <IconButton
                        className='table-row-icon-button'
                        onClick={() => {
                          handleDownloadLink(Number(documentId));
                        }}
                        disabled={downloadLoading}
                        aria-label={`download button`}
                    >
                      <DownloadIcon/>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('buttons.sign')} placement='top' arrow>
                    <IconButton
                        className='table-row-icon-button'
                        onClick={() => {
                          handleSign(
                              Number(documentId),
                              documentName.substring(documentName.length - 3, documentName.length)
                          );
                        }}
                        aria-label={`sign button`}
                        disabled={downloadLoading}
                    >
                      <DocumentSigningIcon/>
                    </IconButton>
                  </Tooltip>
                </>
            ) : (
                <>
                  <Tooltip title={t('buttons.download')} placement='top' arrow>
                    <IconButton
                        className='table-row-icon-button'
                        onClick={() => console.log('')}
                        aria-label={t('buttons.download')}
                    >
                      <DownloadIcon/>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('buttons.fillTemplate')} placement='top' arrow>
                    <IconButton
                        className='table-row-icon-button'
                        onClick={() => console.log('')}
                        aria-label={t('buttons.fillTemplate')}
                    >
                      <DocumentSigningIcon/>
                    </IconButton>
                  </Tooltip>
                </>
            )}
            <Button
                onClick={() => {
                  editable
                      ? category === 'templates'
                          ? updateTemplate()
                          : saveContent()
                      : setEditable(true);
                }}
                style={{marginLeft: '0.5rem', marginBottom: '0.5rem'}}
                type='button'
                role='button'
                variant='contained'
                size='medium'
            >
              {!editable ? t('buttons.edit') : t('buttons.save')}
            </Button>
          </div>
          <div className='p-4 pt-0 bg-grey' style={{borderRadius: '0 0 1.25rem 1.25rem'}}>
            <JoditEditor
                ref={editor}
                config={{
                  readonly: !editable,
                  buttons: "bold,italic,underline,strikethrough,eraser,ul,ol,font,fontsize,paragraph,lineHeight,copy,source,left,right,center,image,paste,selectall,hr,table,link,symbols,indent,outdent,print"
                }}
                value={documentContent}
                onBlur={(newContent) => setDocumentContent(newContent)} // preferred to use only this option to update the content for performance reasons
            />
          </div>
        </TabPanel>
        {aiDocumentDialogVisible && (
            <DialogItem
                isErrorMessage={false}
                open={aiDocumentDialogVisible}
                title={t('dialogTitles.aiSummary')}
                noDefaultActionsRow={true}
                headerIcon={<AIIcon/>}
                handleClose={() => {
                  setAiDocumentDialogVisible(false);
                }}
            >
              <div className='d-flex mb-3'>
                <p className='text-center flex-grow-1 ps-2 pe-2'>{t('messages.reviewSettings')}</p>
                <Tooltip
                    className='flex-shrink-0'
                    title={t('messages.AIFunctionalityMessage')}
                    placement='top'
                >
                  <InfoIcon fontSize='small' color='secondary'/>
                </Tooltip>
              </div>
              <hr/>
              <Grid>
                <div className='setting-panel-body ps-0 pe-0'>
                  <ValidatorForm onSubmit={() => console.log('')}>
                    <Grid container className='form-controls' spacing={4}>
                      <Grid item xs={12} className='pt-3' md={6}>
                        <InputLabel htmlFor='documentTypeSelect'>{t('labels.documentType')}</InputLabel>
                        <Select
                            className='w-100'
                            id='documentTypeSelect'
                            onChange={(e: SelectChangeEvent<typeof documentType>) =>
                                setDocumentType(e.target.value)
                            }
                            name='documentType'
                            value={documentType}
                            tabIndex={-1}
                            defaultOpen={true}
                        >
                          <MenuItem value='contract'>{t('documentTypes.contract')}</MenuItem>
                          <MenuItem value='insurance'>{t('documentTypes.insurance')}</MenuItem>
                          <MenuItem value='invoice'>{t('documentTypes.invoice')}</MenuItem>
                          <MenuItem value='certificate'>{t('documentTypes.certificate')}</MenuItem>
                          <MenuItem value='other'>{t('documentTypes.other')}</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} className='pt-3' md={6}>
                        <Autocomplete
                            className='mt-0'
                            multiple
                            id='tags-standard'
                            openOnFocus={true}
                            options={purposes}
                            disableCloseOnSelect={true}
                            isOptionEqualToValue={(option, value) => option.title === value.title}
                            getOptionLabel={(option) => option.title}
                            value={purpose}
                            onChange={(
                                event: ChangeEvent<HTMLInputElement>,
                                newValue:
                                    | {
                                  title: string;
                                  value: string;
                                }[]
                                    | null
                            ) => {
                              setPurpose(newValue);
                            }}
                            renderOption={(props, option, {selected}) => {
                              const {...optionProps} = props;
                              return (
                                  <li key={option.value} {...optionProps}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{marginRight: 8}}
                                        checked={selected}
                                    />
                                    {option.title}
                                  </li>
                              );
                            }}
                            renderInput={(params) => (
                                <TextField {...params} variant='standard' label={t('labels.purpose')}/>
                            )}
                        />
                      </Grid>
                      <Grid item xs={12} className='pt-3' md={6}>
                        <TextValidator
                            select
                            label={t('labels.resultFormat')}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              setResultFormat(e.target.value);
                            }}
                            name='resultFormat'
                            value={resultFormat}
                        >
                          <MenuItem value={''}>{''}</MenuItem>
                          <MenuItem value='table'>{t('resultFormat.table')}</MenuItem>
                          <MenuItem value='bullet point'>{t('resultFormat.bulletPoint')}</MenuItem>
                          <MenuItem value='narrative format'>
                            {t('resultFormat.narrativeFormat')}
                          </MenuItem>
                        </TextValidator>
                      </Grid>
                      <Grid item xs={12} className='pt-3' md={6}>
                        <TextValidator
                            select
                            label={t('labels.detailLevel')}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              setDetailLevel(e.target.value);
                            }}
                            name='detailLevel'
                            value={detailLevel}
                        >
                          <MenuItem value='high level'>{t('detailLevel.highLevel')}</MenuItem>
                          <MenuItem value='detailed'>{t('detailLevel.detailed')}</MenuItem>
                        </TextValidator>
                      </Grid>
                      <Grid item xs={12} md={12} className='pt-3 checkbox-row d-flex align-items-start'>
                        <Checkbox
                            style={{marginLeft: -10}}
                            id='simplifyTechnicalOrLegalJargon'
                            checked={simplifyJargon}
                            onChange={(event) => setSimplifyJargon(event.target.checked)}
                        />
                        <label htmlFor='simplifyTechnicalOrLegalJargon'>
                          {t('labels.simplifyJargon')}
                        </label>
                      </Grid>
                      <Grid item xs={12} className='pt-3' md={12}>
                        <InputLabel htmlFor='contextualInformation'>
                          {t('labels.contextualInformation')}
                        </InputLabel>
                        <textarea
                            id='contextualInformation'
                            name='contextualInformation'
                            value={contextualInformation}
                            placeholder={t('messages.contextualInformationPlaceholder')}
                            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                              setContextualInformation(e.target.value);
                            }}
                        />
                      </Grid>
                      <Grid
                          item
                          xs={12}
                          md={12}
                          className='pt-0 buttons-row d-flex flex-wrap justify-content-end'
                      >
                        <hr className='w-100 mb-3'/>
                        <Button
                            type='button'
                            role='button'
                            variant='outlined'
                            size='medium'
                            style={{marginLeft: '0.5rem', marginBottom: '0.5rem'}}
                            onClick={() => {
                              setAiDocumentDialogVisible(false);
                            }}
                        >
                          {t('buttons.goBack')}
                        </Button>
                        <Button
                            onClick={() => handleAnalyze()}
                            style={{marginLeft: '0.5rem', marginBottom: '0.5rem'}}
                            type='button'
                            role='button'
                            variant='contained'
                            size='medium'
                        >
                          {t('buttons.analyze')}
                        </Button>
                      </Grid>
                    </Grid>
                  </ValidatorForm>
                </div>
              </Grid>
            </DialogItem>
        )}
        {formatMessage && (
            <DialogItem
                isErrorMessage={false}
                open={formatMessage}
                title={null}
                text={t('messages.pdfMessage')}
                handleClose={() => setFormatMessage(false)}
            />
        )}
        {loading ? <ProgressOverlay/> : null}
        {analysisLoading ? (
            <div
                className='position-fixed w-100 vh-100 d-flex align-items-center justify-content-center'
                style={{backgroundColor: 'rgba(255, 255, 255, 0.7)', zIndex: 3000, left: 0, top: 0}}
            >
              <PacmanLoader loading={analysisLoading} color='#6414DB'/>
            </div>
        ) : null}
      </>
  );
};

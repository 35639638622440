import React, { useState, useEffect } from 'react';
import { TemplateTableRowModel } from '../../../../models/template-table-row.model';
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useTranslation } from 'react-i18next';
import DialogItem from '../../../../common/dialog-item/DialogItem';
import { NWClient } from '../../../../client/NWClient';
import { ProgressOverlay } from '../../../../common/progress-overlay/ProgressOverlay';
import { toast } from 'react-toastify';
import { AITemplatePlaceholderModel } from '../../../../models/ai.template.placeholder.model';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

const TemplateTableRow = ({
  placeholderId,
  data,
  deletePlaceholder,
  documentUploadHtmlId,
  documentUploadId,
  index,
}: TemplateTableRowModel) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [field, setField] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const [placeholder, setPlaceholder] = useState<string>('');
  const [editMode, setEditMode] = useState<boolean>(false);
  const [placeholderIdValue, setPlaceholderIdValue] = useState<number>(placeholderId);
  const [deletePlaceholderMessageVisible, setDeletePlaceholderMessageVisible] =
    useState<boolean>(false);
  const { t } = useTranslation();
  const token = localStorage.getItem('access_token');
  const handleDeleteCurrentPlaceholder = () => {
    setLoading(true);
    NWClient.delete(token, 'document-template-placeholder', placeholderIdValue)
      .then(() => {
        toast.success(t('messages.templatePlaceholderDeleted'), {
          theme: 'colored',
        });
      })
      .catch((error) => {
        toast.error(error.message ? error.message : t('messages.errorOccurred'), {
          theme: 'colored',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const request = () => {
    setLoading(true);
    const data = {
      data: { field, value, placeholder },
      document_upload: documentUploadId,
      document_upload_html: documentUploadHtmlId,
    };
    if (placeholderId) {
      return NWClient.put(token, 'document-template-placeholder', placeholderId, data, true);
    } else {
      return NWClient.post(token, 'document-template-placeholder', data);
    }
  };
  const handleSavePlaceholder = () => {
    if (editMode || !placeholderIdValue) {
      request()
        .then((res: AITemplatePlaceholderModel) => {
          toast.success(
            placeholderIdValue
              ? t('messages.templatePlaceholderUpdated')
              : t('messages.templatePlaceholderCreated'),
            {
              theme: 'colored',
            }
          );
          setEditMode(false);
          setPlaceholderIdValue(res.id);
        })
        .catch((error) => {
          toast.error(error.message ? error.message : t('messages.errorOccurred'), {
            theme: 'colored',
          });
        })
        .finally(() => setLoading(false));
    } else {
      setEditMode(true);
    }
  };
  useEffect(() => {
    setField(data.field);
    setValue(data.value);
    setPlaceholder(data.placeholder);
  }, [data]);

  useEffect(() => {
    setPlaceholderIdValue(placeholderId);
  }, [placeholderId]);
  return (
    <>
      <tr>
        <td>
          <input
            type='text'
            title={field}
            disabled={Boolean(placeholderIdValue)}
            value={field}
            onChange={(e) => setField(e.target.value)}
          />
        </td>
        <td>
          <input
            type='text'
            title={value}
            disabled={Boolean(placeholderIdValue)}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </td>
        <td>
          <input
            type='text'
            title={placeholder}
            disabled={Boolean(placeholderIdValue) && !editMode}
            value={placeholder}
            onChange={(e) => setPlaceholder(e.target.value)}
          />
        </td>
        <td className='vertical-align-middle'>
          <div className='d-flex align-items-center'>
            <IconButton
              aria-label='delete'
              onClick={() => {
                if (placeholderIdValue) {
                  setDeletePlaceholderMessageVisible(true);
                } else {
                  deletePlaceholder();
                }
              }}
            >
              <Tooltip title={t('messages.deleteTemplatePlaceholder')} placement='top' arrow>
                <DeleteOutlineOutlinedIcon fontSize='small' style={{ marginRight: 0 }} />
              </Tooltip>
            </IconButton>

            <IconButton className='table-row-icon-button' onClick={() => handleSavePlaceholder()}>
              <Tooltip
                title={
                  placeholderIdValue
                    ? editMode
                      ? t('buttons.update')
                      : t('buttons.edit')
                    : t('buttons.save')
                }
                placement='top'
                arrow
              >
                {placeholderIdValue ? (
                  editMode ? (
                    <CheckCircleOutlineOutlinedIcon fontSize='small' style={{ marginRight: 0 }} />
                  ) : (
                    <ModeEditOutlinedIcon fontSize='small' style={{ marginRight: 0 }} />
                  )
                ) : (
                  <CheckCircleOutlineOutlinedIcon fontSize='small' style={{ marginRight: 0 }} />
                )}
              </Tooltip>
            </IconButton>
          </div>
        </td>
      </tr>
      {deletePlaceholderMessageVisible && (
        <DialogItem
          isErrorMessage={false}
          open={deletePlaceholderMessageVisible}
          title={
            deletePlaceholderMessageVisible
              ? t('dialogTitles.deletePlaceholder')
              : t('dialogTitles.deleteDefault')
          }
          text={
            deletePlaceholderMessageVisible
              ? t('messages.deletePlaceholderDialog')
              : t('messages.deleteDefault')
          }
          noDefaultActionsRow={true}
          handleClose={() => {
            setDeletePlaceholderMessageVisible(false);
          }}
        >
          <div className='buttons-row d-flex flex-wrap justify-content-end mt-3'>
            <Button
              onClick={() => {
                if (!placeholderId && placeholderIdValue) {
                  handleDeleteCurrentPlaceholder();
                  deletePlaceholder();
                } else {
                  deletePlaceholder();
                }
                setDeletePlaceholderMessageVisible(false);
              }}
              style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
              type='button'
              role='button'
              variant='outlined'
              size='medium'
            >
              {t('buttons.delete')}
            </Button>
            <Button
              type='button'
              role='button'
              variant='contained'
              size='medium'
              style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
              onClick={() => {
                setDeletePlaceholderMessageVisible(false);
              }}
            >
              {t('buttons.goBack')}
            </Button>
          </div>
        </DialogItem>
      )}
      {loading ? <ProgressOverlay /> : null}
    </>
  );
};

export default TemplateTableRow;

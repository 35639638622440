import React, { useState, useEffect } from 'react';
import { TabPanel } from '../../../../common/tabpanel/TabPanel';
import { TabProps } from './types';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { ProgressOverlay } from '../../../../common/progress-overlay/ProgressOverlay';
import { NWClient } from '../../../../client/NWClient';
import { toast } from 'react-toastify';
import DialogItem from '../../../../common/dialog-item/DialogItem';
import { AITemplateModel } from '../../../../models/ai.template.model';
import { DocumentTemplateModel } from '../../../../models/document.template.model';
import parse from 'html-react-parser';
import TemplateTableRow from '../TemplateTableRow';
import { useAppSelector } from '../../../../common/hooks/useAppSelector';
import { selectUser } from '../../../../slices/user/userSlice';
import { ROUTES } from '../../../../common/constants/routes';

export const DocumentTemplateTab = ({
  currentTab,
  htmlContent,
  documentUploadHtmlId,
}: TabProps) => {
  const { t } = useTranslation();
  const token = localStorage.getItem('access_token');
  const currentUser = useAppSelector(selectUser);
  const { documentId } = useParams();
  const navigate = useNavigate();
  const [documentContent, setDocumentContent] = useState('');
  const [viewTemplateMessage, setViewTemplateMessage] = useState(false);
  const [documentTemplate, setDocumentTemplate] = useState<DocumentTemplateModel>(null);
  const [template, setTemplate] = useState<
    {
      id?: number;
      data: { field: string; value: string; placeholder: string };
    }[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  const addRow = () => {
    const templateCopy = template.slice();
    const item = { data: { field: '', value: '', placeholder: '' } };
    templateCopy.push(item);
    setTemplate(templateCopy);
  };

  const creatingTemplateInitial = () => {
    if (documentId) {
      NWClient.post(token, 'ai-template', {
        /*data: {},*/
        document_upload_id: Number(documentId),
        /*document_upload_html: document.document_upload_html[document.document_upload_html.length - 1]*/
      })
        .then((res: AITemplateModel[]) => {
          setTemplate(
            res.map((el) => ({
              id: el.id,
              data: { ...el.data },
            }))
          );
        })
        .catch((error) => {
          toast.error(error.message ? error.message : t('messages.errorOccurred'), {
            theme: 'colored',
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const creatingTemplate = () => {
    setLoading(true);
    if (documentId) {
      NWClient.post(token, 'document-template', {
        document_upload: Number(documentId),
        user: currentUser.id,
      })
        .then((res: DocumentTemplateModel) => {
          toast.success(t('messages.templateCreated'), {
            theme: 'colored',
          });
          setDocumentTemplate(res);
        })
        .catch((error) => {
          toast.error(error.message ? error.message : t('messages.errorOccurred'), {
            theme: 'colored',
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const deletePlaceholder = (id?: number, index?: number) => {
    if (id) {
      setLoading(true);
      NWClient.delete(token, 'document-template-placeholder', id)
        .then(() => {
          toast.success(t('messages.templatePlaceholderDeleted'), {
            theme: 'colored',
          });
          creatingTemplateInitial();
        })
        .catch((error) => {
          toast.error(error.message ? error.message : t('messages.errorOccurred'), {
            theme: 'colored',
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (index) {
      const templateCopy = template.slice();
      templateCopy.splice(index, 1);
      setTemplate(templateCopy);
    }
  };

  useEffect(() => {
    setDocumentContent(htmlContent);
  }, [htmlContent]);

  useEffect(() => {
    setLoading(true);
    creatingTemplateInitial();
    NWClient.list(token, 'document-template', {
      user: currentUser.id,
      document_upload: documentId,
    }).then((res: DocumentTemplateModel[]) => {
      if (res.length > 0) {
        setDocumentTemplate(res[res.length - 1]);
      }
    });
  }, [documentId]);

  return (
    <>
      <TabPanel value={currentTab} index={4} prefix={'vertical'}>
        <>
          <div className='buttons-row content-buttons align-items-center bg-white position-sticky p-4 d-flex flex-wrap justify-content-end'>
            <Button
              onClick={() => {
                if (documentTemplate) {
                  setViewTemplateMessage(true);
                } else {
                  creatingTemplate();
                }
              }}
              style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
              type='button'
              role='button'
              variant='contained'
              size='medium'
            >
              {documentTemplate ? t('buttons.viewTemplate') : t('buttons.createTemplate')}
            </Button>
          </div>
          <div className='d-flex'>
            <div
              className='p-2 w-50 overflow-auto flex-shrink-0'
              style={{ height: 'calc(100vh - 280px)' }}
            >
              <table className='w-100 template-placeholders-table'>
                <thead>
                  <tr>
                    <th>{t('labels.field')}</th>
                    <th>{t('labels.value')}</th>
                    <th>{t('labels.placeholder')}</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {template.map((el, i) => (
                    <TemplateTableRow
                      key={el.id ? el.id : Number(documentId + i)}
                      index={i}
                      placeholderId={el.id}
                      data={el.data}
                      documentUploadHtmlId={documentUploadHtmlId}
                      documentUploadId={Number(documentId)}
                      deletePlaceholder={() => deletePlaceholder(el.id, i)}
                    />
                  ))}
                </tbody>
              </table>
              <div className='buttons-row d-flex justify-content-end align-items-start'>
                <Button
                  onClick={() => addRow()}
                  style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
                  type='button'
                  role='button'
                  variant='contained'
                  size='medium'
                >
                  +
                </Button>
              </div>
            </div>
            <div
              className='p-2 w-50 overflow-auto flex-shrink-0'
              style={{ borderLeft: '1px solid #C7C6C6', height: 'calc(100vh - 280px)' }}
            >
              {parse(documentContent)}
            </div>
          </div>
        </>
      </TabPanel>

      {viewTemplateMessage && (
        <DialogItem
          isErrorMessage={false}
          open={viewTemplateMessage}
          title={null}
          text={t('messages.viewTemplateMessage')}
          handleClose={() => setViewTemplateMessage(false)}
          noDefaultActionsRow={true}
        >
          <div className='buttons-row d-flex flex-wrap justify-content-end mt-3'>
            <Button
              type='button'
              role='button'
              variant='contained'
              size='medium'
              style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
              onClick={() => {
                setViewTemplateMessage(false);
              }}
            >
              {t('buttons.goBack')}
            </Button>
            <Button
              onClick={() =>
                navigate(`${ROUTES.DOCUMENT_VAULT}/templates/${documentTemplate.id}?initialTab=1`)
              }
              style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
              type='button'
              role='button'
              variant='contained'
              size='medium'
            >
              {t('buttons.viewTemplate')}
            </Button>
          </div>
        </DialogItem>
      )}
      {loading ? <ProgressOverlay /> : null}
    </>
  );
};
